<template>
  <div class="user-mes ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack"></span>短信验证</div>
    <div class="image"></div>
    <div class="sub-tips">修改密码需要手机短信验证。</div>
    <div class="center">
      <input type="text" class="ly-input phone" v-model="form.phone" placeholder="请输入手机号">
      <div class="code-box">
        <input type="text" class="ly-input code" v-model="form.smscode" placeholder="请输入验证码">
        <button type="button" :disabled="isGet" class="txt" @click="getyzm">{{ yzmtext }}</button>
      </div>
      <button class="ly-btn choose" type="button" @click="handleNext">下一步</button>
    </div>
  </div>
</template>
<script>

import {postSendSms, postVerification} from '../../api/api.js'
import {setPassInfo} from '../../utils/auth'
import {isPhoneNumber} from '../../utils/util'
import {Toast} from 'mint-ui';

export default {
  name: 'UserMes',
  components: {},
  computed: {},
  data() {
    return {
      isGet: false,
      yzmtext: '获取验证码',
      form: {
        mobile: '',
        phone: '',
        smscode: ''
      }
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    getyzm() {
      // 判断并验证手机号
      if (this.form.phone && isPhoneNumber(this.form.phone)) {
        const body = {
          mobile: this.form.phone
        }
        postSendSms(body).then(res => {
          Toast('短信已发送，请注意查收!')
          this.countDown()
        })
        this.isGet = true;
      } else {
        this.isGet = false;
        Toast('请检查输入的手机号码是否正确!')
      }
    },

    countDown() {
      let i = 120;
      let timer = setInterval(() => {
        this.yzmtext = i + 's';
        i--;
        if (i < 0) {
          this.isGet = false;
          this.yzmtext = '获取验证码';
          clearInterval(timer);
        }
      }, 1000);
    },
    handleNext() {
      //  判断电话和验证码是否填写
      if (!this.form.smscode) {
        Toast('请输入验证码')
        return
      }
      if (this.form.phone && isPhoneNumber(this.form.phone)) {
        postVerification(this.form).then(res => {
          setPassInfo(res.data.result)
          this.goWay('/user/new')
        })
      }

    },
    goBack() {
      this.$router.go(-1)
      // history.go(-1)
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">

.user-mes {
  width: 100%;
  height: auto;
  background: #FFFFFF;

  .image {
    width: 100%;
    height: 2.4rem;
    background: url("~@/assets/img/user/message.png") no-repeat bottom center;
    background-size: 1.12rem;
  }

  .sub-tips {
    line-height: 0.8rem;
    text-align: center;
    font-size: 0.22rem;
    font-weight: 400;
    color: #333333;
  }

  .center {
    width: 5.89rem;
    height: auto;
    margin: 0 auto;
    padding: 0.6rem 0;

    .ly-input {
      width: 5rem;
      padding-left: 0.89rem;
      height: 0.88rem;
      border: 1px solid #999999;
      box-shadow: 0 0.05rem 0.12rem 0 rgba(0, 0, 0, 0.18);
      border-radius: 0.1rem;
      outline: none;
      //text-indent: 0.9rem;
      background-repeat: no-repeat;
      background-color: #FFFFFF;
      background-size: 0.28rem;
      background-position: 0.36rem center;
      margin: 0 auto 0.4rem;
    }

    .phone {
      background-image: url("~@/assets/img/user/i-phone.png");
    }

    .code {
      width: 3rem;
      padding-right: 2rem;
      background-image: url("~@/assets/img/user/i-code.png");
    }

    .code-box {
      position: relative;

      .txt {
        background: #FFFFFF;
        outline: none;
        border: none;
        width: 1.95rem;
        height: 0.28rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: #0088FF;
        border-left: 1px solid #E2E2E2;
        position: absolute;
        top: 0.34rem;
        right: 0;
        line-height: 0.28rem;
      }
    }

    .ly-btn {
      width: 5.89rem;
      height: 0.88rem;
      margin: 0 auto;
      background: #0088FF;
      box-shadow: 0 0.1rem 0.13rem 0 rgba(0, 136, 255, 0.34);
      border-radius: 0.1rem;
      outline: none;
      border: none;
      color: #FFFFFF;
      font-size: 0.36rem;
    }
  }

}
</style>
